.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* APP STYLES */
.ant-card-grid:hover {
  background-color: #1a78bd;
  color: white;
}
.document__content {
  padding: 0 130px !important;
  min-height: calc(100vh - 350px);
}
#card-product {
  width: auto !important;
  cursor: auto;
}
img.blackramps-active {
  max-width: 250px !important;
}
img.motobox-active {
  max-width: 250px !important;
}
img.normal {
  max-width: 150px !important;
  margin: auto;
  cursor: pointer;
}
.document.gopro {
  width: 100%;
  margin: auto;
  overflow-x: hidden;
}
 .ant-col-8 {
  /* max-width: 32% !important; */
  margin: 2px auto;
}
.document.gopro .ant-card-body {
  text-align: center;
}
.ant-card-bordered {
  border: 5px solid #e5e5e5 !important;
}
#card-product-selected img{
  max-width: 160px !important;
  margin: auto;
}
.gopro .ant-col {
  margin: 5px auto !important;
}
#card-product-selected {
  max-width: 215px;
}
.ant-modal-body .ant-col.gutter-row {
  margin: auto;
}
.ant-form-item label {
  font-size: 0.9em;
}
/* // TABLE */
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}
table tr th {
  font-size: 0.7em;
}
table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}
table tbody tr td#desc-product {
  font-size: 0.8em;
}
@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
.document__title {
  padding: 15px 50px 50px 50px;
  background-color: black;
  color: white;
  font-size: 5em;
  font-weight: 800;
}
h2.document__subtitle {
  box-shadow: 0 0 5px rgb(0 0 0 / 60%);
  width: 90%;
  padding: 5px;
    min-height: 50px;
    margin: -61px auto 30px;
    background: #ff4d4f;
}
a.whats-img img {
  position: fixed;
  z-index: 999;
  width: 50px;
  bottom: 0;
  right: 0;
}
a.call-img img {
  position: fixed;
  z-index: 999;
  width: 39px;
  bottom: 18%;
  right: 9px;
}
.site-footer
{
  background-color:#26272b;
  padding:7px 0px 15px 0;
  font-size:15px;
  line-height:24px;
  color:#737373;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a, .site-footer p
{
  color:white;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    /* margin-bottom:30px */
  }
  .brands-payments ul {
    margin: auto !Important;
    position: relative !important;
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
#btn-login {
  position: absolute;
  right: 0.3%;
  background: transparent;
  margin: 8px;
}
.banner-scrolled {
  height: 40px;
  background: cornflowerblue;
  margin-top: -40px;
  transition: 0.3s
}
.banner-scrolled p {
  position: relative;
  color: white;
  padding: 9px;
  font-weight: 700;
  font-size: 1.1em;  
  animation: rightThenLeft 18.5s infinite alternate ease-in-out;
  /* animation-name: run; */
  /* animation-duration: 9s; */
}
.row-stock {
  height: 130px !important;
}
.out-stock {
  border: 2px solid red !important;
}
@keyframes rightThenLeft {
  from {
    left: -90%;
  }

  to {
    /* left: auto; */
    left: 90%;
  }
}
@media (max-width:767px)
{
  .banner-scrolled {
    height: 85px !important;
    margin-top: -90px !important;
  }
  #modal-init button {
    bottom: 17% !important;
    right: 33% !important;
    position: relative !important;
    color: black !important;
    border: 2px solid black !important;
    left: 22% !important;
    margin-top: 15px !important;
  }
  #modal-init #init-message {
    font-size: 1em !important;
    margin-top: 25px !important;
  }
  img.img-logo {
    width: 120px !important;
    margin: 0 10px !important;
  }
  .blackramps .document__header img {
    margin: -5px -10px !important;
  }
  .motobox .document__header img {
    margin: 64px 6px !important;
    height: 80px !important;
  }
  .document__content {
    padding: 0 30px !important;
    /* min-height: calc(100vh - 350px); */
  }
  h1.document__title {
    font-size: 2em !important;
    padding: 85px 50px 50px 50px !important;
  }
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
table#list-orders, #list-orders th, #list-orders td {
  border: 1px solid black;
}
table#list-orders {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100% !important;
  border: 1px solid #ddd !important;
}

#list-orders th, #list-orders td {
  text-align: left;
  padding: 8px;
}

#list-orders tr:nth-child(even){background-color: #f2f2f2}

table#table-wishlist {
  margin: auto
}
table#table-wishlist img {
  width: 70px;
}
.modal-wishlist .ant-modal-body {
  overflow-y: auto !important;
  max-height: 410px !important;
}
.brands-payments ul {
  display: inline-flex;
  position: absolute;
    margin: -25px 0;
    /* float: right; */
    left: 0;
}
.brands-payments ul li {
  display: block;
}
.brands-payments img {
  width: 30px;
  margin: 5px;
}
/*  BLACK RAMPS SECTION */

.blackramps .document__header img {
  height: 100px;
  margin: 25px;
  position: absolute;
  left: 0;
}
.motobox .document__header img {
  height: 100px;
  margin: 45px;
  position: absolute;
  left: 0;
}
img.img-logo {
  width: 180px;
    position: absolute;
    left: 0;
    margin: 15px;
}

#modal-init img {
  width: 100%;
}
#modal-init button {
  position: absolute;
  bottom: 25%;
  right: 40%;
  width: 150px;
}
#init-message {
  position: absolute;
  margin: 12% 10%;
  z-index: 999;
  border: 2.1px solid white;
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 2em;
  font-weight: bold;
}
.ant-modal-close {
  color: black !important;
  font-size: 2em !important;
  padding: 10px !important;
}
.ant-modal-mask {
  /* background-color: rgba(0, 0, 0, 0.96) !important; */
}
.modal-init-ecommerce .ant-form-horizontal .ant-form-item-control {
  max-width: 100% !important;
}