body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* aux styles */
button#btn-wishlist.desactive {
  position: fixed !important;
  z-index: 999 !important;
  left: 0 !important;
  /* top: 0; */
  bottom: 3% !important;
  margin-left: -572px !important;
}
button#btn-wishlist.active {
  position: fixed !important;
  z-index: 999 !important;
  left: 0 !important;
  /* top: 0; */
  bottom: 10% !important;
  margin-left: 15px !important;
}
.ant-card-meta-description {
  font-weight: 700;
}
/* SPINNER */
.loader{
  margin: 15% auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: block;
  background: white;
}
.load{
  position: absolute;
  top: 50%;
  right: 50%;
  width: 360px;
  height: 360px;
  margin-right: -180px;
  margin-top: -180px;
}
.circle{
  border-radius: 100%;
  position: absolute;
  top: 50%;
  right: 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#circle-1{
  width: 160px;
  height: 160px;
  margin-top: -80px;
  margin-right: -80px;
  /* border: 16px solid black;
  border-top: 16px solid black;
  border-bottom: 16px solid black; */
  animation-duration: 2s;
  animation-name: spin1;
}
#circle-2{
  width: 150px;
  height: 150px;
  margin-top: -65px;
  margin-right: -65px;
  /* border: 8px solid blue;
  border-left: 8px solid blue;
  border-right: 8px solid blue; */
  animation-name: spin1;
  animation-duration: 4s;
}
#circle-3{
  width: 128px;
  height: 128px;
  margin-top: -58px;
  margin-right: -58px;
  border: 12px solid red;
  border-left: 12px solid blue;
  border-right: 12px solid blue;
  animation-name: spin1;
  animation-duration: 4s;
  animation-direction: reverse;
}
@keyframes spin1{
    to {transform: rotate(360deg);}
}

#menu-content {
  border: 1px solid white;
  position: absolute;
  height: 35px;
  right: 0;
  margin: 10px;
  top: 6%;
}

#menu-content li {
  height: 35px;
    margin: -5px auto;
}

.ant-alert-info {
  cursor: pointer;
}
.ant-card-meta-title {
  white-space: normal !important;
}

.partners img {
  width: 120px;
  padding: 10px;
}
.partners {
  background: white;
  display: inline-flex;
  width: 100%;
  }
  .partners .col-md-4 {
    margin: auto;
  }